import React from "react"
import {
  FaFacebookSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
} from "react-icons/fa"
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://www.instagram.com/mv.radiology/?hl=en">
          <FaFacebookSquare className="social-icon facebook-icon"></FaFacebookSquare>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/mv.radiology/?hl=en">
          <FaInstagramSquare className="social-icon dribble-icon"></FaInstagramSquare>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCdDAzBMGZ8Vg-9LPphs15Iw">
          <FaYoutubeSquare className="social-icon youtube-icon"></FaYoutubeSquare>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
